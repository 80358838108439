/* Toastify
--------------------------------------------------------------------------------------------------------*/
.Toastify__toast {
  z-index: 9999;
  border-left: 4px solid #222629;
  border-radius: 4px;
  padding: 15px 40px;
  font-family: "Lato", sans-serif;
  background-color: #fff !important;
  position: relative;
}
.Toastify__toast-icon {
  display: none;
}
.Toastify__toast .Toastify__close-button {
  color: #bababa;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
}
.Toastify__toast .Toastify__toast-body {
  font-size: 14px;
  color: var(--lightTextColor);
}

.Toastify__toast::after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 19px;
  left: 10px;
  background-size: 24px 24px;
}
.Toastify__toast--success {
  border-left-color: #2bde3f;
}
.Toastify__toast--success::after {
  background-image: var(--SuccessIconSVG);
}
.Toastify__toast--warning {
  border-left-color: #ffc007;
}
.Toastify__toast--warning::after {
  background-image: var(--warningIconSVG);
}
.Toastify__toast--error {
  border-left-color: #fb5151;
}
.Toastify__toast--error::after {
  background-image: var(--errorIconSVG);
}
.Toastify__toast--info {
  border-left-color: #1d72f3;
}
.Toastify__toast--info::after {
  background-image: var(--infoIconSVG);
}

.confirmation-block > img {
  width: 26px;
  height: 26px;
  display: block;
}
.confirmation-block .media-body {
  font-size: 16px;
  color: var(--lightTextColor);
  line-height: 23px;
}

.form-primary .form-control.is-invalid,
.form-secondary .form-control.error {
  border-color: var(--themeColor);
}

.suggested-block ul li.active {
  background-color: var(--themeColor) !important;
  color: var(--secondaryTextColor);
}

.spinner-disable {
  display: none;
}
#faq-wrapper {
  background-color: var(--secondaryTextColor);
}

.modal-footer {
  justify-content: flex-start;
}

.custom-control-label.error::before {
  border-color: var(--themeColor) !important;
}
.address-block .icon-btn.disable {
  cursor: default;
}
@media (min-width: 992px) {
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show > .nav-link {
    color: var(--secondaryTextColor);
  }
  .navbar-light .navbar-nav .nav-link {
    color: var(--secondaryTextColor);
  }

  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover {
    color: var(--secondaryTextColor);
  }
}

.term-and-condition {
  line-height: 28px;
  margin-left: 4px;
  cursor: pointer;
  color: var(--themeColor) !important;
}

.img-remove {
  background-image: none !important;
}

.form-secondary .password-block .password-icon {
  right: 0px;
  top: 6px;
}

/* Loading 
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.cssload-spin-box {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff, 15px -15px #a9a9a9;
  -o-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff, 15px -15px #a9a9a9;
  -ms-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff, 15px -15px #a9a9a9;
  -webkit-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff, 15px -15px #a9a9a9;
  -moz-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff, 15px -15px #0a19ff;
  animation: cssload-spin ease infinite 4.6s;
  -o-animation: cssload-spin ease infinite 4.6s;
  -ms-animation: cssload-spin ease infinite 4.6s;
  -webkit-animation: cssload-spin ease infinite 4.6s;
  -moz-animation: cssload-spin ease infinite 4.6s;
}
@keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #fff;
  }
  25% {
    box-shadow: -15px 15px #fff, -15px -15px #fff, 15px -15px #fff, 15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #fff, 15px 15px #fff, -15px 15px #fff;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff, -15px -15px #fff;
  }
}
@-ms-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #a9a9a9, -15px 15px #a9a9a9, -15px -15px #a9a9a9, 15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff, -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff, -15px -15px #fff;
  }
}
@-webkit-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff, -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff, -15px -15px #fff;
  }
}
@-moz-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff, -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff, -15px -15px #fff;
  }
}

/* loading-battery
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
@keyframes load {
  0% {
    top: 4.5rem;
  }
  70% {
    top: 1.125rem;
  }
  90% {
    top: 0;
  }
  95% {
    top: 0;
  }
  100% {
    top: 4.5rem;
  }
}
@keyframes liquid-1 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  22% {
    height: 0.28125rem;
    top: 0.375rem;
    opacity: 1;
  }
  25% {
    top: -0.25rem;
  }
  35% {
    height: 1.125rem;
    top: -0.5rem;
  }
  55% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 0.61875rem;
    opacity: 1;
    top: -0.275rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
@keyframes liquid-2 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  17.5% {
    height: 0.28125rem;
    top: 0.2rem;
    opacity: 1;
  }
  20% {
    top: -0.25rem;
  }
  25% {
    height: 1.40625rem;
    top: -0.625rem;
  }
  45% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 1.40625rem;
    opacity: 1;
    top: -0.5rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
.battery {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -2rem;
  margin-left: -1.12rem;
  width: 2.25rem;
  height: 4.5rem;
  box-shadow: 0 0 0 0.2rem #425c77;
  background: white;
  border-radius: 0.09rem;
}
.battery:before {
  content: "";
  position: absolute;
  left: 0.5625rem;
  right: 0.5625rem;
  top: -0.3375rem;
  height: 0.3375rem;
  width: 1.125rem;
  background: #425c77;
  border-radius: 0.18rem;
}
.battery:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-right: 2.25rem solid transparent;
  border-bottom: 4.05rem solid rgba(255, 255, 255, 0.325);
}
.liquid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 2.25rem;
  background: #71fb85;
  animation: load 2.25s infinite;
}
.liquid:after,
.liquid:before {
  content: "";
  position: absolute;
  top: -0.5rem;
  height: 1.125rem;
  width: 1.4625rem;
  background: #71fb85;
  border-radius: 50%;
  opacity: 0;
}
.liquid:after {
  right: 0;
  animation: liquid-1 2.25s infinite;
}
.liquid:before {
  left: 0;
  animation: liquid-2 2.25s infinite;
}

@keyframes power {
  0% {
    color: #bcbcbc;
  }
  75% {
    color: #bcbcbc;
    text-shadow: none;
  }
  90% {
    color: #58fa70;
    text-shadow: 0 0 0.8em #71fb85;
  }
  100% {
    color: #bcbcbc;
    text-shadow: none;
  }
}
.ModelBtn {
  text-align: right;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.InputMask input[type="text"] {
  -webkit-text-security: disc;
}

.form-primary .icon {
  width: 50px;
  height: 40px;
}

.mesg-box.process-block h2 {
  text-transform: none;
}

.whitelabel label {
  color: white !important;
}
.available-price {
  font-size: 18px;
  font-weight: 700;
}
.available-price .icon {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}
.grid-table .table th .icon-text {
  position: relative;
  padding-right: 30px;
  display: inline-block;
}
.grid-table .table th .icon-text .icon {
  position: absolute;
  right: 0px;
  top: 0;
  display: block;
}
.grid-table .table th .icon-text .icon img {
  width: 14px;
  height: 14px;
  margin-top: 1px;
  vertical-align: top;
  display: inline-block;
}
.red-border {
  border: 1px solid var(--themeColor);
}

.alert-primary {
  color: #666866;
  background-color: #fbf5f4;
  border-radius: 8px;
  border: 1px solid #e8d9d3;
  padding: 10px;
}
.apply-text-outer {
  position: relative;
}
.apply-text-outer .form-control {
  padding-right: 100px;
}
.apply-text-outer .apply-text {
  font-size: 14px;
  color: var(--themeColor);
  font-weight: 600;
  position: absolute;
  right: 0px;
  bottom: 10px;
}
.apply-text-outer .applied {
  color: #7ac142;
  font-size: 14px;
  font-weight: 600;
  padding-right: 24px;
  position: absolute;
  right: 0px;
  bottom: 10px;
  display: none;
}
.apply-text-outer .applied .icon {
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  right: 0px;
  top: -3px;
}
.apply-text-outer.success .applied {
  display: block;
}
.apply-text-outer.success .apply-text {
  display: block;
  cursor: pointer;
  z-index: 2;
}

.checkmark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark__check {
  stroke-width: 5.25;
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
#terms_condition * {
  line-height: normal;
  font-family: "Lato", sans-serif im !important;
}
.donate-block {
  border-radius: 6px;
  overflow: hidden;
  background-color: #f5f5f5;
}
.donate-block .media-body {
  padding: 10px 10px 10px 0px;
}
.donate-block h6 {
  font-size: 14px;
  padding-bottom: 5px;
}
.donate-block > img {
  max-width: 130px;
  min-height: 130px;
}
.read-more {
  font-size: 13px;
  color: var(--themeColor);
  font-weight: 600;
  margin-bottom: 5px;
  display: inline-block;
}
.CookieConsent {
  justify-content: center !important;
}
.CookieConsent > div:first-child {
  flex: 0 0 auto !important;
  max-width: 100%;
}
.card-number-control {
  position: relative;
}
.card-number-control .form-control {
  padding-right: 45px;
}
.card-number-control .card-logo {
  width: 50px;
  height: 40px;
  position: absolute;
  right: 5px;
  top: 0px;
}

.available-card-list img {
  height: 18px;
  display: block;
  margin-top: 5px;
}
.open-source h1 {
  margin: 0;
  font-size: 22px;
  font-weight: 700;
}
.open-source .detail-block {
  background-color: #f9f9f9;
  border: 1px solid #dcdcdc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.open-source .detail-block p {
  padding: 0;
  color: var(--blackTextColor);
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 18px;
}
.open-source .detail-block .links-wrapper {
  display: block;
  width: 100%;
  text-align: right;
}
.open-source .detail-block .link {
  color: var(--linkTextColor);
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
}
.open-source .detail-block .link:last-child {
  margin-right: 0;
}
.open-source {
  background-color: #fff;
}
.open-source .wrapper {
  font-family: Lato, sans-serif;
  padding: 15px;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.badge {
  font-size: 13px;
  font-weight: 400;
  min-width: 82px;
  line-height: 24px;
  height: 24px;
  border-radius: 24px;
  padding: 0 10px;
}
.grid-table .table td > .icon.actions img,
.icon.actions img {
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}
.actions-dropdown .dropdown-toggle {
  text-align: left;
  padding-left: 15px;
}
.actions-dropdown .dropdown-toggle:before {
  position: absolute;
  right: 10px;
  top: 17px;
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary {
  background-color: var(--themeColor) !important;
  border: 0px !important;
}
.btn-outline-primary {
  color: var(--themeColor);
  border-color: var(--themeColor);
}
.btn-outline-primary:after {
  display: none;
}
.btn-outline-primary {
  border: 1px solid var(--themeColor) !important;
  background-color: #fff;
}
.btn-outline-primary span {
  color: var(--themeColor);
}
.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover,
.btn-outline-primary.dropdown-toggle.focus,
.btn-outline-primary.dropdown-toggle:focus,
.btn-outline-primary.dropdown-toggle:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--themeColor) !important;
}
.btn-outline-primary.focus span,
.btn-outline-primary:focus span,
.btn-outline-primary:hover span,
.btn-outline-primary:not(:disabled):not(.disabled).active span,
.btn-outline-primary:not(:disabled):not(.disabled):active span,
.show > .btn-outline-primary.dropdown-toggle span {
  color: var(--secondaryTextColor);
}
.btn-outline-primary.btn-icon .icon {
  overflow: hidden;
}
.btn-outline-primary.btn-icon .icon img {
  width: 35px;
  height: 16px;
  position: absolute;
  top: 0px;
  left: 0;
  display: block;
  transition: all 0.3s;
}
.btn-outline-primary.btn-icon.focus .icon img,
.btn-outline-primary.btn-icon:focus .icon img,
.btn-outline-primary.btn-icon:hover .icon img {
  margin-left: -19px;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary {
  background-color: var(--lightTextColor) !important;
  border: 0px !important;
}
.btn-link span {
  color: var(--primaryTextColor);
}
.btn-link.focus,
.btn-link:focus,
.btn-link:hover,
.btn-link {
  background-color: transparent !important;
  border: 0px !important;
}
.actions-dropdown .dropdown-menu .dropdown-item:focus,
.actions-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #f2f2f2;
}
.withdraw-dropdown img {
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  left: 10px;
  right: 10px;
  /* top: 50%; */
  /* margin-top: -9px; */
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.message-div {
  width: 500px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
  border-radius: 5px;
}

.public_card_id :focus {
  background-image: none !important;
}

.forgot-pass-message {
  margin-bottom: 10px;
  color: #fb5151;
}
.charity-donation-header {
  font-size: 17px;
  color: var(--primaryTextColor);
  text-shadow: 1px 0 var(--primaryTextColor);
  letter-spacing: 1px;
  font-weight: bolder;
}
.padding-0 {
  padding: 0 !important;
}
.subscription-wrapper {
  background: var(--loginBackgroundJPG) no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.subscription-header {
  color: var(--secondaryTextColor);
  font-size: 30px;
}
.subscription-header-div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-flow: column;
  min-height: 100vh;
}

#exportHistory.focus,
#exportHistory:focus,
#exportHistory:hover,
#exportHistory {
  background-color: transparent !important;
  border: 1px solid var(--themeColor) !important;
}
#exportHistory.btn:before {
  background-color: #fff;
}
#exportHistory.icon-btn {
  padding: 0;
  position: relative;
  width: 40px;
}
#exportHistory.icon-btn .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 40px;
}

.visually-hidden {
  display: none;
}

.btn-blue {
  color: #fff !important;
  background-color: #138496 !important;
  border-color: #117a8b !important;
}

.btn-blue:hover,
.btn-blue:focus {
  color: #fff !important;
  background-color: #138496 !important;
  border-color: #117a8b !important;
}

.btn-green {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-green:hover,
.btn-green:focus {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.charity-modal .modal-body {
  padding-bottom: 0px;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

/* inline styles */
.themeTextColor {
  color: var(--themeColor);
}
.charityTextColor {
  color: #666;
}
.charityLabelText {
  font-weight: 600;
  color: var(--blackTextColor);
}
.modalHeader {
  color: var(--themeColor);
  font-weight: 700;
}
.modalBody {
  font-size: 18px;
  padding: 20px 20px 15px 20px !important;
}
.loaderBody {
  background-color: white;
  width: 100%;
  height: 100vh;
}
.successBadge {
  background-color: #2cae1e;
  border-radius: 4px;
}

#themeLink {
  text-decoration: underline;
  cursor: pointer;
  color: var(--themeColor);
}
.patternField {
  border: none;
  display: inline-flex;
}

.language-dropdown {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9;
}

.address-search-box > ul {
  position: absolute !important;
  top: 40px !important;
  /* width: 88% !important; */
  max-height: 250px !important;
  overflow: auto !important;
  margin-top: 5px !important;
  border: 1px solid #e4e4e4;
  border-radius: 3px !important;
  z-index: 2000 !important;
  background-color: white !important;
  padding: 0px;
}

.address-search-box > ul > li {
  border-bottom: 1px solid #e4e4e4;
  color: var(--lightTextColor);
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address-search-box > ul > li:hover {
  background-color: var(--themeColor);
  color: white;
}

.address-search-box > input {
  height: 46px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dadada;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: 1px;
}

.inline-icons {
  display: inline-flex !important;
  column-gap: 10px;
}

.inline-icons:hover {
  cursor: pointer;
}

.min-height {
  min-height: 180px;
}

.pb-0 {
  padding-bottom: 0px;
}
/* 
.menuClose:hover{
    background-color: #e3e3e3;
} */

.sameAsPersonal {
  float: right;
  width: 28%;
}

.controlWrapper {
  display: flex;
  padding: 0px;
}

.customSearch-wrapper {
  flex-grow: 1;
}

.detail-block-about-us {
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 40px;
}

.detail-block-about-us > p {
  font-weight: 900;
  padding-bottom: 0px;
}

.detail-block-about-us:first-child {
  border-top: 1px solid #dcdcdc;
}

.detail-block-open-source {
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.charity-modal .donation-details {
  color: var(--lightTextColor) !important;
}

.fit-img {
  width: 50px;
  height: 20px;
  object-fit: contain;
}

.charity-modal .donation-details {
  color: #666 !important;
}
.charity-modal .note-label {
  color: #d73a1f;
}

.copyright .privacy-policy-link {
  color: #d6310f;
  text-decoration: none;
  cursor: pointer;
}

.copyright .privacy-policy-link:hover {
  text-decoration: underline;
  color: #666666;
}
