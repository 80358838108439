@charset "utf-8";

/*
------------------------------------------------------------
Project Name : Instavolt Driver Portal
;
------------------------------------------------------------
*/

/* Reset 
--------------------------------------------------------------------------------------------------------*/

/* 
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* General 
--------------------------------------------------------------------------------------------------------*/
* {
  outline: none !important;
}
body:not(.page-loaded) {
  opacity: 0;
}
.window-resizing *,
body:not(.page-loaded) * {
  transition: none !important;
}
.btn.icon-btn .icon,
.btn-icon span .icon,
a,
.container {
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
}
html,
body {
  min-width: 320px;
  min-height: 100vh;
}
body {
  font: 14px / 18px var(--fontStyle);
  font-weight: 400;
  -ms-overflow-style: scrollbar;
  background-color: #f3f3f3;
  opacity: 1 !important;
}
.wrapper {
  min-height: 100vh;
}
a {
  color: var(--blackTextColor);
  text-decoration: none;
}
a:hover {
  color: #999;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
  padding: 0 0 20px;
  font-weight: 600;
  color: #222629;
}
h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  display: block;
}
h1,
h2 {
  font-size: 26px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
p {
  font-size: 14px;
  font-weight: 400;
  padding: 0 0 20px;
}
strong,
.strong {
  font-weight: 700;
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  appearance: none;
}
::-webkit-input-placeholder {
  color: #a4b1bd;
}
::-moz-placeholder {
  color: #a4b1bd;
}
:-ms-input-placeholder {
  color: #a4b1bd;
}
:-moz-placeholder {
  color: #a4b1bd;
}
::-moz-focus-inner {
  border: 0;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--blackTextColor);
}
option:not(:checked) {
  color: black;
}

/* inline style replacement --------------------------------------------------------------- */

.cookiePolicy {
  color: var(--secondaryTextColor);
  text-decoration: underline;
}

.h100 {
  height: 100%;
}
.w100 {
  width: 100%;
}

.avatar {
  height: 40;
  width: 40;
}
.dashboardHeader {
  height: 400;
}
.displayNone {
  display: none;
}
.receiptItem {
  width: 100%;
  height: 800px;
}
.flexRow {
  flex-direction: row;
}
.floatRight {
  float: right;
}
.maxWidth25 {
  max-width: 25%;
}
.maxWidth31 {
  max-width: 31%;
}
.errorMsg {
  font-size: "19px";
  line-height: "20px";
}
.actionHeader {
  width: 80px;
}
.fixModalSize {
  overflow: auto;
  max-height: 350px;
}

.avatarIcon {
  display: "inline-block" !important;
  max-width: 140 !important;
}
.menuItemIcon {
  width: "22px";
  height: "22px";
  left: "9px";
  top: "47%";
}
.locationCol {
  max-width: 450;
}
.remarkCol {
  max-width: 350;
}
.codeFixWidth {
  width: 304;
}

/* loading-peeek
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.peeek-loading {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.peeek-loading ul {
  position: absolute;
  left: calc(50% - 0.7em);
  top: calc(50% - 4.2em);
  display: inline-block;
  text-indent: 2.8em;
}
.peeek-loading ul li:after,
.peeek-loading ul:after {
  width: 1.4em;
  height: 1.4em;
  background-color: #fff;
  border-radius: 100%;
}
.peeek-loading ul li:after,
.peeek-loading ul:after {
  content: "";
  display: block;
}
.peeek-loading ul:after {
  position: absolute;
  top: 2.8em;
}
.peeek-loading li {
  position: absolute;
  padding-bottom: 5.6em;
  top: 0;
  left: 0;
}
.peeek-loading li:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: 0.125s;
}
.peeek-loading li:nth-child(1):after {
  animation-delay: 0.125s;
}
.peeek-loading li:nth-child(2) {
  transform: rotate(36deg);
  animation-delay: 0.25s;
}
.peeek-loading li:nth-child(2):after {
  animation-delay: 0.25s;
}
.peeek-loading li:nth-child(3) {
  transform: rotate(72deg);
  animation-delay: 0.375s;
}
.peeek-loading li:nth-child(3):after {
  animation-delay: 0.375s;
}
.peeek-loading li:nth-child(4) {
  transform: rotate(108deg);
  animation-delay: 0.5s;
}
.peeek-loading li:nth-child(4):after {
  animation-delay: 0.5s;
}
.peeek-loading li:nth-child(5) {
  transform: rotate(144deg);
  animation-delay: 0.625s;
}
.peeek-loading li:nth-child(5):after {
  animation-delay: 0.625s;
}
.peeek-loading li:nth-child(6) {
  transform: rotate(180deg);
  animation-delay: 0.75s;
}
.peeek-loading li:nth-child(6):after {
  animation-delay: 0.75s;
}
.peeek-loading li:nth-child(7) {
  transform: rotate(216deg);
  animation-delay: 0.875s;
}
.peeek-loading li:nth-child(7):after {
  animation-delay: 0.875s;
}
.peeek-loading li:nth-child(8) {
  transform: rotate(252deg);
  animation-delay: 1s;
}
.peeek-loading li:nth-child(8):after {
  animation-delay: 1s;
}
.peeek-loading li:nth-child(9) {
  transform: rotate(288deg);
  animation-delay: 1.125s;
}
.peeek-loading li:nth-child(9):after {
  animation-delay: 1.125s;
}
.peeek-loading li:nth-child(10) {
  transform: rotate(324deg);
  animation-delay: 1.25s;
}
.peeek-loading li:nth-child(10):after {
  animation-delay: 1.25s;
}
.peeek-loading li {
  animation: dotAnimation 2.5s infinite;
}
@keyframes dotAnimation {
  0%,
  55%,
  100% {
    padding: 0 0 5.6em 0;
  }
  5%,
  50% {
    padding: 2.8em 0;
  }
}
.peeek-loading li:after {
  animation: dotAnimationTwo 2.5s infinite;
}
@-webkit-keyframes dotAnimationTwo {
  0%,
  55%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  5%,
  50% {
    opacity: 0.5;
    transform: scale(0.5);
  }
}

/* loading-battery
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
@keyframes load {
  0% {
    top: 4.5rem;
  }
  70% {
    top: 1.125rem;
  }
  90% {
    top: 0;
  }
  95% {
    top: 0;
  }
  100% {
    top: 4.5rem;
  }
}
@keyframes liquid-1 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  22% {
    height: 0.28125rem;
    top: 0.375rem;
    opacity: 1;
  }
  25% {
    top: -0.25rem;
  }
  35% {
    height: 1.125rem;
    top: -0.5rem;
  }
  55% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 0.61875rem;
    opacity: 1;
    top: -0.275rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
@keyframes liquid-2 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  17.5% {
    height: 0.28125rem;
    top: 0.2rem;
    opacity: 1;
  }
  20% {
    top: -0.25rem;
  }
  25% {
    height: 1.40625rem;
    top: -0.625rem;
  }
  45% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 1.40625rem;
    opacity: 1;
    top: -0.5rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
.battery {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -2rem;
  margin-left: -1.12rem;
  width: 2.25rem;
  height: 4.5rem;
  box-shadow: 0 0 0 0.2rem #425c77;
  background: #fff;
  border-radius: 0.09rem;
}
.battery:before {
  content: "";
  position: absolute;
  left: 0.5625rem;
  right: 0.5625rem;
  top: -0.3375rem;
  height: 0.3375rem;
  width: 1.125rem;
  background: #425c77;
  border-radius: 0.18rem;
}
.battery:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-right: 2.25rem solid transparent;
  border-bottom: 4.05rem solid rgba(255, 255, 255, 0.325);
}
.liquid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 2.25rem;
  background: #71fb85;
  animation: load 2.25s infinite;
}
.liquid:after,
.liquid:before {
  content: "";
  position: absolute;
  top: -0.5rem;
  height: 1.125rem;
  width: 1.4625rem;
  background: #71fb85;
  border-radius: 50%;
  opacity: 0;
}
.liquid:after {
  right: 0;
  animation: liquid-1 2.25s infinite;
}
.liquid:before {
  left: 0;
  animation: liquid-2 2.25s infinite;
}

@keyframes power {
  0% {
    color: #bcbcbc;
  }
  75% {
    color: #bcbcbc;
    text-shadow: none;
  }
  90% {
    color: #58fa70;
    text-shadow: 0 0 0.8em #71fb85;
  }
  100% {
    color: #bcbcbc;
    text-shadow: none;
  }
}

/* Form controle
--------------------------------------------------------------------------------------------------------*/
.border-top-2 {
  border-top: 2px solid #e7e7e7 !important;
}
.max-w-100 {
  max-width: 100% !important;
}
.text-primary {
  color: var(--themeColor) !important;
}
.text-secondary {
  color: #21a598 !important;
}
.btn,
.btn-sm {
  overflow: hidden;
  position: relative;
  padding: 7px 20px;
  border: 1px solid #e0e0e0 !important;
  height: 40px;
  border-radius: 4px !important;
  box-shadow: none !important;
  outline: none !important;
}
.btn span {
  color: var(--secondaryTextColor);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  text-transform: capitalize;
  position: relative;
  display: block;
  text-shadow: none !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.btn-sm:after,
.btn:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 25%;
  height: 100%;
  width: 50%;
  opacity: 0;
  transition: all 0.4s;
}
.btn-sm:hover:after,
.btn:hover:after {
  width: 100%;
  left: 0;
  opacity: 1;
  border-radius: 4px;
}
.btn-sm {
  height: 36px;
}
.btn-icon {
  position: relative;
  padding-left: 40px;
}
.btn-icon .icon {
  position: absolute;
  left: 15px;
  top: 50%;
  display: block;
  height: 16px;
  width: 16px;
  margin-top: -8px;
  z-index: 2;
}
.btn-sm.btn-icon {
  padding-left: 14px;
}
.btn-sm.btn-icon .icon {
  left: 8px;
}
.btn-icon span {
  position: relative;
}
.btn-icon span .icon {
  position: absolute;
  left: auto;
  right: 10px;
  top: 50%;
  display: block;
  height: 18px;
  width: 18px;
  margin-top: -7px;
}
.btn-icon:hover span .icon {
  right: 0;
}

.btn.icon-btn {
  padding: 0px;
  position: relative;
  width: 40px;
}
.btn.icon-btn .icon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 40px;
}
.btn.icon-btn:hover .icon {
  left: -40px;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary {
  background-color: var(--primaryBtnColor) !important;
  border: 0px !important;
}
.btn-outline-primary:after {
  display: none;
}
.btn-outline-primary {
  border: 1px solid var(--primaryBtnColor) !important;
  background-color: #fff;
}
.btn-outline-primary span {
  color: var(--themeColor);
}
.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
  background-color: var(--themeColor) !important;
}
.btn-outline-primary.focus span,
.btn-outline-primary:focus span,
.btn-outline-primary:hover span {
  color: var(--secondaryTextColor);
}
.btn-outline-primary.btn-icon .icon {
  overflow: hidden;
}
.btn-outline-primary.btn-icon .icon img {
  width: 35px;
  height: 16px;
  position: absolute;
  top: 0px;
  left: 0;
  display: block;
  transition: all 0.3s;
}
.btn-outline-primary.btn-icon.focus .icon img,
.btn-outline-primary.btn-icon:focus .icon img,
.btn-outline-primary.btn-icon:hover .icon img {
  margin-left: -19px;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary {
  background-color: var(--secondaryBtnColor) !important;
  border: 0px !important;
}
.btn-link span {
  color: var(--primaryTextColor);
}
.btn-link.focus,
.btn-link:focus,
.btn-link:hover,
.btn-link {
  background-color: transparent !important;
  border: 0px !important;
}

/*Form Secondary --------------*/
.form-secondary .form-group {
  margin-bottom: 20px;
  position: relative;
}
.form-secondary .form-control {
  font-size: 16px;
  color: var(--primaryTextColor);
  font-weight: 400;
  height: 48px;
  background-color: transparent;
  padding: 10px 0 0 0;
  border: 0px;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
  position: relative;
  z-index: 2;
}
.form-secondary .form-group .float-label {
  position: absolute;
  top: 21px;
  color: var(--primaryTextColor);
  font-size: 14px;
  font-weight: 400;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.form-secondary .form-group.error .float-label {
  color: var(--themeColor);
}
.form-secondary .form-group.error .form-control {
  border-bottom-color: var(--themeColor);
}
.form-secondary .form-group.value-exists .float-label {
  top: -3px;
  font-size: 13px;
  color: #b9b9b9;
}
.form-secondary .form-group .form-control:not(:-ms-placeholder-shown) ~ .float-label {
  top: -24px;
  font-size: 12px;
}
.form-secondary .form-group .form-control:focus ~ .float-label,
.form-group select ~ .float-label {
  top: -24px;
  font-size: 12px;
}

/*Form primary --------------*/
.form-primary .form-group {
  margin-bottom: 20px;
  position: relative;
}
.form-primary .form-group label {
  font-size: 14px;
  color: var(--lightTextColor);
  padding-bottom: 5px;
}
.form-primary .form-group label small {
  font-size: 12px;
}
.form-primary .custom-select,
.form-primary .form-group .form-control {
  font-size: 16px;
  color: var(--primaryTextColor);
  font-weight: 400;
  height: 40px;
  border-color: #dadada;
  border-radius: 4px;
}
.form-primary .form-group .error {
  font-size: 12px;
  padding: 0 15px;
  /* position: absolute; */
  left: 0;
  max-width: 100%;
}
.form-primary .input-group {
  border-radius: 4px;
}

.form-primary .custom-select {
  width: 100%;
  background-image: none;
  background-image: var(--customSelectArrowSVG);
}
.form-primary .form-group-icon {
  position: relative;
  padding-right: 70px;
}
.form-primary .form-group-icon .icon {
  width: 50px;
  height: 40px;
}

.input-group .input-group-prepend.last-child {
  margin-right: 0px;
  margin-left: -1px;
}
.input-group .input-group-prepend.last-child .input-group-text {
  border-radius: 0px 4px 4px 0px;
}
.custom-select:focus,
.form-control:focus {
  border-color: var(--blackTextColor) !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  outline: none !important;
}

.address-block {
  position: relative;
}
.address-block .form-control {
  padding-right: 40px;
}
.address-block .icon-btn {
  width: 38px;
  height: 38px;
  background-color: #f5f5f5;
  position: absolute;
  right: 1px;
  top: 1px;
  display: block;
  border-radius: 0 4px 4px 0;
}
.address-block .icon-btn .icon {
  width: 38px;
  height: 38px;
  display: block;
}

.password-block {
  position: relative;
}
.password-block .form-control {
  padding-right: 35px;
}
.password-block .password-icon {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  right: 5px;
  bottom: 0px;
  width: 35px;
  height: 40px;
  background: var(--lockSVG) no-repeat center center;
  background-size: 20px 20px;
}
.password-block.show .password-icon {
  background-image: var(--unlockSVG);
}
.form-primary .custom-control label.custom-control-label,
.custom-control-label {
  font-size: 14px;
  color: var(--lightTextColor);
  font-weight: 400;
  line-height: 28px;
  vertical-align: middle;
  cursor: pointer;
  padding-left: 3px;
}
.custom-control-label::after,
.custom-control-label::before {
  width: 20px;
  height: 20px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--themeColor);
  border-color: var(--themeColor) !important;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: var(--themeColor);
  border-color: var(--themeColor);
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.25) !important;
  border-color: var(--themeColor) !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: var(--checkSVG);
  top: 0.3rem;
  left: -1.45rem;
}

/* custom-radio ----- */
.custom-radio .custom-control-label::after,
.custom-radio .custom-control-label::before {
  width: 18px;
  height: 18px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--themeColor);
  border-color: var(--themeColor) !important;
}

/* The switch ------*/
.form-primary .toggle-switch-outer {
  position: relative;
  padding-right: 60px;
  display: inline-block;
}
.form-primary .toggle-switch-block {
  position: absolute;
  right: 0px;
  top: -4px;
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  padding: 0px !important;
}
.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  border-radius: 4px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d0d0d0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  border-radius: 3px;
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.toggle-switch input:checked + .slider {
  background-color: var(--themeColor);
}
.toggle-switch input:focus + .slider {
  box-shadow: 0 0 1px var(--themeColor);
}
.toggle-switch input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Header
--------------------------------------------------------------------------------------------------------*/
.header {
  padding-top: 20px;
  height: 317px;
  background: var(--innerPageBgPNG) no-repeat;
  background-position: center bottom;
  background-size: cover;
}
.header-right-bar {
  float: right;
}
.header-right-bar .user-block .dropdown-toggle {
  min-height: 42px;
  position: relative;
  padding: 10px 20px 10px 50px;
  display: block;
}
.header-right-bar .user-block .dropdown-toggle .user-icon {
  width: 42px;
  height: 42px;
  border-radius: 42px;
  position: absolute;
  left: 0px;
  top: -2px;
  display: block;
}
.header-right-bar .user-block .dropdown-toggle .name {
  font-size: 14px;
  color: var(--secondaryTextColor);
}
.header-right-bar .user-block .dropdown-toggle::after {
  border-top-color: var(--secondaryTextColor);
}
.header-right-bar .user-block .dropdown-menu {
  margin-top: 5px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.13);
}
.header-right-bar .user-block .dropdown-item {
  font-size: 14px;
  color: var(--lightTextColor);
  position: relative;
  padding: 10px 10px 10px 38px;
  border-radius: 6px;
}
.header-right-bar .user-block .dropdown-item img {
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -9px;
}
.header-right-bar .user-block .dropdown-item:hover {
  background-color: #f0f0f0;
}

/* MainNavigation
--------------------------------------------------------------------------------------------------------*/
.navbar-toggler {
  width: 45px;
  padding: 3px 8px;
  position: absolute;
  left: 0;
  top: 5px;
  border: none;
}
.navbar-toggler span {
  display: block;
  height: 2px;
  margin: 6px 0;
  background-color: #dbdbdb;
  position: relative;
}
.navbar-toggler:hover {
  background-color: rgba(0, 0, 0, 1);
}
.navbar-toggler:hover span {
  background-color: #fff;
}
.navbar .navbar-nav li {
  padding: 0 10px;
}
.navbar .navbar-nav li a {
  font-size: 16px;
  color: var(--secondaryTextColor);
  padding: 15px 10px;
  position: relative;
}
.navbar .navbar-nav li a:after {
  content: "";
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0px;
  left: 0;
  background-color: var(--navlinkUnderline);
}
.navbar .navbar-nav li:hover a {
  background-color: rgba(255, 255, 255, 0.25);
}
.navbar .navbar-nav li.active a:after {
  height: 4px;
}

/* Main Content
--------------------------------------------------------------------------------------------------------*/
.main-content {
  padding: 0px 0px 40px;
  margin-top: -140px;
}
.card {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 1px 1.732px 15px 0px rgba(0, 0, 0, 0.15);
  text-align: center;
}
.card .card-body {
  padding: 0 15px 30px;
  max-width: 910px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}
.card .card-header {
  text-align: left;
  padding: 20px 30px;
  background: none !important;
  border: 0px;
}
.card .card-header h1 {
  font-size: 34px;
  color: var(--themeColor);
  font-weight: 300;
  padding: 0px;
}
.card .card-title {
  font-size: 24px;
  color: var(--primaryTextColor);
  font-weight: 700;
  padding: 0 0 20px 0;
  margin: 0px;
  text-transform: capitalize;
}
.card .hr {
  height: 1px;
  width: 100%;
  margin: 30px 0;
  background-color: #e0e0e0;
}
.sub-title {
  font-size: 24px;
  color: var(--primaryTextColor);
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
.sub-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--navlinkUnderline);
  display: block;
  width: 80px;
  height: 3px;
}
.label {
  color: var(--lightTextColor);
  font-weight: 400;
}
.label strong {
  color: var(--primaryTextColor);
  font-weight: 700;
}
.max-w-400 {
  max-width: 400px;
}

/* Credit Card
--------------------------------------------------------------------------------------------------------*/
.credit-card {
  border: 0px;
  background: var(--creditCardBgPNG) no-repeat top right;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}
.credit-card .card-header {
  padding: 15px 50px 15px 15px;
  position: relative;
}
.credit-card .card-header h3 {
  font-size: 20px;
  color: var(--primaryTextColor);
  padding-bottom: 0px;
}
.credit-card .card-header .actions {
  position: absolute;
  right: 10px;
  top: 15px;
  display: block;
}
.credit-card .company-logo {
  width: 60px;
  height: 60px;
  display: block;
}
.credit-card .card-body {
  padding: 30px 20px;
}
.credit-card .card-body .card-number span {
  font-size: 24px;
  color: var(--primaryTextColor);
}
.credit-card .card-footer {
  text-align: left;
  background: none;
  border: none;
  padding: 15px 20px 10px;
}
.credit-card .card-footer label {
  font-size: 12px;
  color: var(--primaryTextColor);
  line-height: 12px;
  padding-bottom: 8px;
}
.credit-card .card-footer h6 {
  color: var(--primaryTextColor);
  padding-bottom: 0px;
}
.credit-card .add-card-outer {
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
}
.credit-card .add-card-outer .add-card {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 11px;
  background-color: var(--themeColor);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  z-index: 1;
}
.credit-card .add-card-outer .add-card img {
  width: 20px;
  width: 20px;
  display: block;
  cursor: pointer;
}

.actions .dropdown-toggle {
  cursor: pointer;
  font-weight: 700;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  padding: 0px;
  display: inline-block;
}
.actions .dropdown-toggle:hover {
  background-color: #fff;
}
.actions .dropdown-toggle img {
  width: 32px;
  height: 32px;
  display: block;
}
.actions .dropdown-toggle::after,
.actions .dropdown-toggle::before {
  display: none;
}
.actions .dropdown-menu {
  min-width: 120px;
  padding: 5px;
  border: none !important;
  z-index: 9;
  box-shadow: 3px 3px 14px rgba(37, 79, 115, 0.27);
}
.actions .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: var(--lightTextColor);
  position: relative;
  padding: 8px 15px 8px 35px;
  display: block;
}
.actions .dropdown-menu .dropdown-item img {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 7px;
  display: block;
}
.actions .dropdown-menu .dropdown-item:focus,
.actions .dropdown-menu .dropdown-item:hover {
  background-color: #f2f2f2;
}
.current-balance {
  padding: 20px;
  border: 0px;
  background: var(--currentBalanceBgPNG) no-repeat top left;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}
.current-balance .net-amount-block {
  padding-left: 20px 20px 20px 120px;
}
.current-balance .icon {
  width: 45px;
  height: 45px;
  display: block;
  position: absolute;
  left: 20px;
  top: 20px;
}
.current-balance .card-title {
  font-size: 30px;
  color: var(--primaryTextColor);
  padding-bottom: 0px;
}
.current-balance .card-text {
  font-size: 14px;
  color: var(--lightTextColor);
  padding-bottom: 10px;
}

.select-card {
  position: relative;
}
.select-card label.credit-card {
  position: relative;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  box-shadow: none;
  transition: all 0.25s;
}
.select-card .custom-control-input {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 3;
}
.select-card .custom-control-input:checked ~ label.credit-card {
  border-color: var(--themeColor);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}
.select-card label.credit-card::before {
  content: "";
  border-radius: 0 6px 0px 3px;
  box-shadow: none !important;
  position: absolute;
  left: auto;
  right: -1px;
  top: -1px;
  width: 20px;
  height: 20px;
  opacity: 0;
}
.select-card .custom-control-input:checked ~ label.credit-card::before {
  background: var(--themeColor) var(--checkSVG) no-repeat center center / 12px 12px;
  opacity: 1;
}

.credit-account-block .current-balance {
  position: relative;
  border: 0px;
  padding: 10px 0;
  background-image: none;
  cursor: pointer;
  box-shadow: none;
  background-color: #f7f7f7;
}
.credit-account-block .card-title {
  font-size: 30px;
  padding-bottom: 15px;
}

/*summary-block ---------*/
.current-balance .summary-block {
  border: 0px;
  color: #666;
  text-align: left;
  padding: 0.55rem 0;
}
.current-balance .summary-block .form-group {
  text-align: center;
  margin-bottom: 0px;
}
.current-balance .summary-block .form-group:not(:last-child) {
  border-right: 1px solid #dadada;
}
.current-balance .summary-block .form-group label {
  font-size: 13px;
  padding-bottom: 2px;
}
.current-balance .summary-block .form-group > div {
  padding: 0 5px;
  margin: auto;
  display: inline-block;
  text-align: left;
}
.font-size-sm {
  font-size: 12px;
}
/* Payment Configuration
--------------------------------------------------------------------------------------------------------*/
.payment-Config .payment-config-img {
  width: 319px;
  height: 319px;
  display: block;
}
.payment-option .custom-control {
  margin-bottom: 20px;
  padding: 0px;
}
.payment-option .custom-control .custom-control-label {
  font-size: 18px;
  color: var(--primaryTextColor);
  padding: 20px 20px 20px 95px;
  display: block;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}
.payment-option .custom-control .custom-control-label:hover {
  box-shadow: none;
}
.payment-option .custom-control .custom-control-label p {
  font-size: 13px;
  line-height: 1.2;
  color: var(--lightTextColor);
  padding: 0px;
}
.payment-option .custom-control .custom-control-label .icon {
  width: 50px;
  height: 40px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -18px;
}
.payment-option .custom-control .custom-control-label .icon img {
  width: 50px;
  height: 80px;
  margin-top: -40px;
}
.payment-option .custom-control .custom-control-label::after {
  display: none;
}
.payment-option .custom-control .custom-control-label::before {
  border: 0px;
  border-radius: 0 3px 0 0;
  box-shadow: none !important;
  left: auto;
  right: 0px;
  top: -1px;
  width: 32px;
  opacity: 0;
  height: 32px;
}
.payment-option .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: var(--themeColor) var(--checkSVG) no-repeat center center / 14px 14px;
  opacity: 1;
}
.payment-option .custom-radio .custom-control-input:checked ~ .custom-control-label {
  border-color: var(--themeColor);
  color: var(--themeColor);
  box-shadow: 0px 3px 5px 0px rgba(174, 54, 34, 0.08);
}
.payment-option .custom-radio .custom-control-input:checked ~ .custom-control-label p {
  color: var(--themeColor);
}
.payment-option .custom-radio .custom-control-input:checked ~ .custom-control-label .icon img {
  margin-top: 0;
  transition: all 0.25s;
}

/* grid-table
--------------------------------------------------------------------------------------------------------*/
.grid-table.table-responsive {
  border: 0px;
  margin-bottom: 0px;
}
.min-height.grid-table.table-responsive {
  min-height: 340px;
}
.grid-table .table {
  margin-bottom: 0px;
  min-width: 700px;
}
.grid-table .table > thead > tr > th,
.grid-table .table > tbody > tr > th,
.grid-table .table > tfoot > tr > th,
.grid-table .table > thead > tr > td,
.grid-table .table > tbody > tr > td,
.grid-table .table > tfoot > tr > td {
  color: var(--lightTextColor);
  font-size: 14px;
  font-weight: 400;
  vertical-align: middle;
  padding: 5px 10px;
  border-top: 0px;
  border-bottom: 0;
  white-space: nowrap;
  height: 49px;
}
.grid-table .table > thead > tr > th {
  color: var(--primaryTextColor);
  font-weight: 700;
  white-space: nowrap;
  padding: 12px 10px;
  white-space: nowrap;
}
.grid-table .table > thead > tr > th span {
  position: relative;
}
.grid-table .table.table-border > thead > tr > th,
.grid-table .table.table-border > tbody > tr > th,
.grid-table .table.table-border > tfoot > tr > th,
.grid-table .table.table-border > thead > tr > td,
.grid-table .table.table-border > tbody > tr > td,
.grid-table .table.table-border > tfoot > tr > td {
  border-top: 0px;
  border-bottom: 1px solid #e7e7e7;
}

.grid-table .table th.actions,
.grid-table .table td.actions {
  white-space: nowrap;
}
.grid-table .table td.actions > .icon {
  margin: 0 2px;
  vertical-align: middle;
  border-radius: 32px;
  display: inline-block;
  padding: 6px;
}
.grid-table .table td.actions > .icon img {
  width: 20px;
  height: 20px;
  display: block;
}
.grid-table .table .actions .actions-icon {
  padding: 7px;
  margin: 0 2px;
  vertical-align: middle;
  border-radius: 32px;
  display: inline-block;
  padding: 6px;
  background: none !important;
  border: 0px !important;
}
.grid-table .table .actions .actions-icon img {
  width: 24px;
  height: 24px;
  display: block;
}
.grid-table .table > tbody > tr > td a {
  color: #8f8f8f;
  font-size: 14px;
  font-weight: 400;
}
.grid-table .table > tbody > tr > td a:hover {
  color: var(--blackTextColor);
}
.grid-table .table .actions a:hover,
.grid-table .table .actions a:focus {
  background-color: #eceef1;
}
.grid-table .table-hover > tbody > tr:hover {
  background-color: #f9f9f9;
}
.grid-table td.center,
th.center {
  text-align: center;
}

/* sorting */
.grid-table .table > thead > tr > th.sorting span,
.grid-table .table > thead > tr > th.sorting_asc span,
.grid-table .table > thead > tr > th.sorting_desc span {
  position: relative;
  padding-right: 20px;
  cursor: pointer;
  display: block;
}
.grid-table .table > thead > tr > th span:after {
  content: "";
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  right: auto;
  background-repeat: no-repeat;
  background-size: 8px 10px;
  background-position: right center;
}
.grid-table .table > thead > tr > th.sorting span:after {
  background-image: var(--sortingSVG);
}
.grid-table .table > thead > tr > th.sorting_asc span:after {
  background-image: var(--srotingAscSVG);
}
.grid-table .table > thead > tr > th.sorting_desc span:after {
  background-image: var(--sortingDescSVG);
}

/* Actions Dropdown */
.grid-table .table .actions .dropdown-toggle {
  position: relative;
  cursor: pointer;
  font-weight: 700;
  margin: auto !important;
  width: 32px;
  height: 32px;
  border-radius: 32px !important;
  padding: 0px;
  display: block;
  background-color: #ededed !important;
}
.grid-table .table .actions .dropdown-toggle img {
  position: absolute;
  top: 0px;
  left: 0;
  width: 32px;
  height: 64px;
  display: block;
}
.grid-table .table .actions .dropdown-toggle:hover,
.grid-table .table .actions .show .dropdown-toggle {
  background-color: #b2afaf !important;
}
.grid-table .table .actions .dropdown-toggle:hover img,
.grid-table .table .actions .show .dropdown-toggle img {
  top: -32px;
}
.grid-table .table .actions .dropdown-toggle::after,
.grid-table .table .actions .dropdown-toggle::before {
  display: none;
}
.grid-table .table .actions .dropdown-menu {
  border-radius: 6px;
  transform: translateX(-54px);
  min-width: 100px;
  border: none !important;
  z-index: 9;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}
.grid-table .table .actions .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: #404041;
  position: relative;
  padding: 8px 10px 8px 35px;
  display: block;
}
.grid-table .table .actions .dropdown-menu .dropdown-item img {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 7px;
  display: block;
}
.grid-table .table .actions .dropdown-menu .dropdown-item:hover {
  background-color: #f4f4f4;
}

/* paginetion ----------------------*/
.grid-bottom {
  background: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0 0 6px 6px;
}
.paginetion {
  float: right;
}
.paginetion ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
.paginetion ul li {
  padding: 0px 2px;
  margin: 0px;
  float: left;
  background: none;
}
.paginetion ul li a {
  font-size: 14px;
  color: var(--secondaryBtnColor);
  text-decoration: none;
  background: #fff;
  border: none;
  margin: 0px auto;
  padding: 0px;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  line-height: 31px;
  text-align: center;
  display: block;
}
.paginetion ul li a.active,
.paginetion ul li a.active:hover {
  color: var(--secondaryTextColor);
  text-decoration: none;
  border: none;
  background: var(--themeColor);
}
.paginetion ul li a:hover {
  text-decoration: none;
  background-color: #f0f0f0;
  color: var(--blackTextColor);
}
.paginetion ul li a.prevar,
.paginetion ul li a.nxtar {
  padding: 0 10px;
  width: auto;
}
.show-record {
  float: left;
  font-size: 14px;
  color: #949494;
  padding-left: 0px;
  margin-top: 2px;
}
.show-record .form-label {
  font-size: 14px;
  color: #4f4f4f;
  display: inline-block;
  margin-right: 10px;
}
.show-record .custom-select {
  min-width: 80px;
  width: auto;
  display: inline-block;
}

/* Faqs
--------------------------------------------------------------------------------------------------------*/
.faqs-outer .card .card-header {
  padding: 0px;
}
.faqs-outer .card .card-header .faq-card-title {
  font-size: 18px;
  color: var(--themeColor);
  font-weight: 600;
  line-height: 22px;
  position: relative;
  display: block;
  position: relative;
  padding: 15px 30px 15px 15px;
  text-decoration: none;
}
.faqs-outer .card .card-header .faq-card-title.collapsed {
  color: var(--primaryTextColor);
}
.faqs-outer .card .card-header .faq-card-title:after {
  content: "";
  position: absolute;
  right: 15px;
  top: 20px;
  height: 16px;
  width: 16px;
  background-image: var(--downSVG);
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  transition: all 0.5s;
  transform: rotate(-180deg);
}
.faqs-outer .card .card-header .faq-card-title.collapsed:after {
  transform: rotate(0);
}
.faqs-outer .card {
  padding: 0px;
  border: none;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0px;
  box-shadow: none;
}
.faqs-outer .card:last-child {
  border-bottom: 0px;
}
.faqs-outer .card .card-body {
  font-size: 16px;
  color: var(--lightTextColor);
  line-height: 22px;
  padding: 0 15px 15px;
  max-width: 100%;
}

/* profile
--------------------------------------------------------------------------------------------------------*/
.profile-hading {
  text-align: left;
}
.profile-hading .profile-img {
  width: 92px;
  height: 92px;
}
.profile-hading .media-body {
  font-size: 20px;
  color: #404041;
  padding: 15px;
}
.profile-hading .media-body h1 {
  color: var(--themeColor);
  font-weight: 700;
  padding: 0;
}

/* Modal
--------------------------------------------------------------------------------------------------------*/
.modal .modal-header {
  padding: 15px 15px;
  border-bottom: 1px solid #ededed;
}
.modal .modal-header h3 {
  color: #222629;
  line-height: 1;
  padding-bottom: 0px;
}
.modal .close {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  padding: 5px;
  margin: 0;
  display: inline-block;
  opacity: 1;
}
.modal .close img {
  width: 16px;
  height: 16px;
  display: block;
}
.modal .close:hover {
  background-color: #eceef1;
}
.modal .modal-body {
  padding: 30px 20px;
}
.modal .modal-body .close {
  position: absolute;
  right: 5px;
  top: 5px;
}
.modal .modal-body .title {
  color: var(--primaryTextColor);
  font-size: 26px;
}
.modal .modal-footer {
  padding: 10px 15px 30px;
  border: 0px;
}
.modal .modal-footer .btn {
  min-width: 100px;
}
.modal .modal-content {
  border: 0px;
}
.modal .modal-content p {
  color: #9e9e9e;
}
.suggested-block h5 {
  font-size: 14px;
  color: var(--primaryTextColor);
  padding-bottom: 10px;
}
.suggested-block ul {
  border-top: 1px solid #e4e4e4;
  max-height: 250px;
  overflow: auto;
}
.suggested-block ul li {
  font-size: 14px;
  color: var(--lightTextColor);
  border-bottom: 1px solid #e4e4e4;
  padding: 10px;
  cursor: pointer;
}
.suggested-block ul li:hover {
  background-color: #f2f2f2;
}

/* login
--------------------------------------------------------------------------------------------------------*/
.login-page {
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
}
.login-page .login-background-block {
  background: var(--loginBackgroundJPG) no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}
.login-page .instavolt-logo {
  padding: 30px;
  display: inline-block;
}
.login-page .login-section {
  background-color: #fff;
  width: auto;
  max-width: 655px;
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.login-page .login-outer {
  min-height: 95vh;
  height: 95vh;
  display: table;
  width: 100%;
  vertical-align: middle;
}
.login-page .login-contain {
  margin: 0 auto;
  padding: 45px 135px;
  display: table-cell;
  vertical-align: middle;
}
.login-page .login-contain .title-outer {
  text-align: center;
}
.login-page .login-contain .title {
  font-size: 30px;
  color: var(--primaryTextColor);
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 15px;
  text-align: center;
}
.login-page .login-contain .title img {
  display: block;
  margin: 0 auto 25px;
}
.login-page .login-contain .title .icon {
  width: 116px;
  height: 116px;
  border-radius: 116px;
  background-color: #fbf5f4;
  display: block;
  margin: 0 auto 25px;
}
.login-page .login-contain .title .icon img {
  width: 116px;
  height: 116px;
  display: block;
}
.login-page .login-contain .title-outer p {
  font-size: 14px;
  color: #343a40;
}
.login-page .login-contain .forgot-password {
  font-size: 14px;
  color: var(--themeColor);
  line-height: 2;
}
.login-page .login-contain .forgot-password:hover {
  color: var(--lightTextColor);
}

.login-page .login-contain .social-media a {
  font-size: 13px;
  color: var(--secondaryTextColor);
  border-radius: 4px;
  padding: 7px 20px 7px 20px;
}
.login-page .login-contain .social-media a img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
}
.google-btn {
  background-color: #f4594e;
}
.ios-btn {
  background-color: #000;
}
.facebook-btn {
  background-color: #4a77a9;
}
.login-page .login-contain .social-media a:hover {
  opacity: 0.7;
  box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
}
.login-page .login-contain .login-link {
  color: var(--lightTextColor);
  font-size: 14px;
}
.login-page .login-contain .login-link:hover {
  color: var(--themeColor);
}
.border-top-1 {
  border-top: 1px solid #dedede;
}
.login-page .sign-up {
  font-size: 16px;
  color: var(--lightTextColor);
  text-align: center;
}
.login-page .sign-up a {
  color: var(--themeColor);
  font-weight: 700;
}
.login-page .sign-up a:hover {
  color: var(--lightTextColor);
}
.login-page .Copyright {
  font-size: 12px;
  color: #9d9d9d;
  text-align: center;
  padding: 10px;
  position: relative;
  min-height: 40px;
  margin-top: -40px;
}
.login-contain .btn-back {
  position: absolute;
  left: 15px;
  top: 15px;
}

/* Success messg
--------------------------------------------------------------------------------------------------------*/
.mesg-box img {
  width: 72px;
  height: 72px;
  display: block;
  margin: 0 auto 10px;
}
.mesg-box.process-block img {
  width: auto;
  height: 150px;
}
.mesg-box.process-block h2 {
  color: #b4b4b4;
}
.mesg-box h2 {
  font-size: 34px;
  text-transform: capitalize;
  padding-bottom: 20px;
  text-align: center;
}
.mesg-box .text-success {
  color: #3ec834;
}
.mesg-box .text-error {
  color: #ff8400;
}
.mesg-box p {
  font-size: 20px;
  color: var(--lightTextColor);
}
.transaction-details {
  text-align: center;
  padding: 10px;
  width: auto;
  margin: auto;
  display: table;
  background-color: #f4f4f4;
  border-radius: 12px;
}
.transaction-details li {
  display: block;
  padding: 10px;
}
.transaction-details li strong {
  font-size: 24px;
  color: var(--primaryTextColor);
}

.min-vh-50 {
  min-height: 500px !important;
}

/* Footer
--------------------------------------------------------------------------------------------------------*/
.footer {
  background: none;
  padding: 0px;
  text-align: center;
  position: relative;
  min-height: 40px;
  margin-top: -40px; /* Give Footer height to margin top */
}
.copyright {
  font-size: 13px;
  color: #727272;
  text-align: center;
}
/* Responsive Media Queries
--------------------------------------------------------------------------------------------------------*/
@media (min-width: 991px) {
  .showLogo {
    display: none;
  }
}

@media (max-width: 1199px) {
  .navbar .navbar-nav li {
    padding: 0px;
  }
  .login-page .login-section {
    max-width: 455px;
  }
  .login-page .login-contain {
    padding: 50px;
  }
}

@media (max-width: 991px) {
  .navbar-brand {
    margin-left: 40px;
    margin-right: 0;
  }
  .menu-overlay {
    display: block;
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    opacity: 1;
    visibility: visible;
    transition: all 0.25s;
  }
  .navbar .menu-overlay.collapsed {
    opacity: 0;
    visibility: hidden;
  }
  .navbar .navbar-collapse {
    margin-left: -300px;
    width: 252px;
    height: 100%;
    display: block;
    overflow: auto;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #fff;
    z-index: 999;
    box-shadow: 0 2px 11px rgba(0, 0, 0, 0.18);
    transition: all 0.25s !important;
  }
  .navbar .navbar-collapse.show {
    margin-left: 0px;
    transition: all 0.25s !important;
  }
  .navbar .navbar-nav li a {
    color: black !important;
    align-items: center;
  }
  .navbar .navbar-nav li a:after {
    display: none;
  }
  .navbar .navbar-nav li:hover a {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .navbar .navbar-nav li.active a {
    color: #fff !important;
    background-color: var(--themeColor);
  }

  .navbar-brand img {
    height: 20px;
  }
  .header {
    height: 237px;
  }
  .header-right-bar .user-block .dropdown-toggle {
    padding: 0;
  }
  .header-right-bar .user-block .dropdown-toggle .user-icon {
    position: inherit;
  }
  .header-right-bar .user-block .dropdown-toggle::after,
  .header-right-bar .user-block .dropdown-toggle .name {
    display: none;
  }
  .navbarIcon {
    width: 20px;
    margin-right: 8px;
  }
  /* paginetion ----------------------*/
  .grid-bottom {
    text-align: center;
  }
  .show-record {
    margin: auto;
    display: table;
    float: none;
  }
  .paginetion {
    float: none;
    margin: auto;
    margin-top: 15px;
    display: table;
  }
  .paginetion ul li a {
    width: 35px;
    height: 35px;
    line-height: 34px;
  }
  .sameAsPersonal {
    float: none !important;
    width: 100% !important;
    padding-left: 1rem;
  }
  .showLogo {
    display: block;
  }
}
@media (max-width: 767px) {
  .card .card-header {
    padding: 20px;
  }
  .login-page .login-background-block {
    min-height: 0;
    background-image: var(--mdLoginBackgroundJPG);
  }
  .login-page .login-outer {
    height: auto;
  }
  .login-page .login-section {
    max-width: 100%;
    height: auto;
  }
  .login-page .login-contain {
    padding: 50px;
  }
  .payment-Config .payment-config-img {
    display: none;
  }
}

@media (max-width: 575px) {
  .login-page .login-contain {
    padding: 50px 15px;
  }
  .current-balance .card-title {
    font-size: 24px;
  }
  .login-page .login-contain .social-media a {
    padding: 7px 10px 7px 7px;
  }
}

/* Toastify
--------------------------------------------------------------------------------------------------------*/
.Toastify__toast {
  z-index: 9999;
  border-left: 4px solid #222629;
  border-radius: 4px;
  padding: 15px 45px;
  font-family: "Lato", sans-serif;
  background-color: #fff !important;
  position: relative;
}
.Toastify__toast .Toastify__close-button {
  color: #bababa;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
}
.Toastify__toast .Toastify__toast-body {
  font-size: 14px;
  color: var(--lightTextColor);
}

.Toastify__toast::after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 19px;
  left: 10px;
  background-size: 24px 24px;
}
.Toastify__toast--success {
  border-left-color: #2bde3f;
}
.Toastify__toast--success::after {
  background-image: var(--SuccessIconSVG);
}
.Toastify__toast--warning {
  border-left-color: #ffc007;
}
.Toastify__toast--warning::after {
  background-image: var(--warningIconSVG);
}
.Toastify__toast--error {
  border-left-color: #fb5151;
}
.Toastify__toast--error::after {
  background-image: var(--errorIconSVG);
}
.Toastify__toast--info {
  border-left-color: #1d72f3;
}
.Toastify__toast--info::after {
  background-image: var(--infoIconSVG);
}

.confirmation-block > img {
  width: 26px;
  height: 26px;
  display: block;
}
.confirmation-block .media-body {
  font-size: 16px;
  color: var(--lightTextColor);
  line-height: 23px;
}

/* Loading 
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.cssload-spin-box {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff, 15px -15px #a9a9a9;
  -o-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff, 15px -15px #a9a9a9;
  -ms-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff, 15px -15px #a9a9a9;
  -webkit-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff, 15px -15px #a9a9a9;
  -moz-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff, 15px -15px #0a19ff;
  animation: cssload-spin ease infinite 4.6s;
  -o-animation: cssload-spin ease infinite 4.6s;
  -ms-animation: cssload-spin ease infinite 4.6s;
  -webkit-animation: cssload-spin ease infinite 4.6s;
  -moz-animation: cssload-spin ease infinite 4.6s;
}
@keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #fff;
  }
  25% {
    box-shadow: -15px 15px #fff, -15px -15px #fff, 15px -15px #fff, 15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #fff, 15px 15px #fff, -15px 15px #fff;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff, -15px -15px #fff;
  }
}
@-ms-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #a9a9a9, -15px 15px #a9a9a9, -15px -15px #a9a9a9, 15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff, -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff, -15px -15px #fff;
  }
}
@-webkit-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff, -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff, -15px -15px #fff;
  }
}
@-moz-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff, -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff, -15px -15px #fff;
  }
}

/* Success messg
--------------------------------------------------------------------------------------------------------*/
.mesg-box img {
  width: 72px;
  height: 72px;
  display: block;
  margin: 0 auto 10px;
}
.mesg-box h2 {
  font-size: 34px;
  text-transform: capitalize;
  padding-bottom: 20px;
  text-align: center;
}
.mesg-box .text-success {
  color: #3ec834;
}
.mesg-box .text-error {
  color: #ff8400;
}
.mesg-box p {
  font-size: 20px;
  color: var(--lightTextColor);
}
.transaction-details {
  text-align: center;
  padding: 10px;
  width: auto;
  margin: auto;
  display: table;
  background-color: #f4f4f4;
  border-radius: 12px;
}
.transaction-details li {
  display: block;
  padding: 10px;
}
.transaction-details li span {
  font-size: 24px;
  color: var(--lightTextColor);
}
.transaction-details li strong span {
  font-size: 24px;
  color: #333;
}
.transaction-details li strong {
  font-size: 24px;
  color: var(--primaryTextColor);
}

.min-vh-50 {
  min-height: 500px !important;
}
/* loading-battery
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
@keyframes load {
  0% {
    top: 4.5rem;
  }
  70% {
    top: 1.125rem;
  }
  90% {
    top: 0;
  }
  95% {
    top: 0;
  }
  100% {
    top: 4.5rem;
  }
}
@keyframes liquid-1 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  22% {
    height: 0.28125rem;
    top: 0.375rem;
    opacity: 1;
  }
  25% {
    top: -0.25rem;
  }
  35% {
    height: 1.125rem;
    top: -0.5rem;
  }
  55% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 0.61875rem;
    opacity: 1;
    top: -0.275rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
@keyframes liquid-2 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  17.5% {
    height: 0.28125rem;
    top: 0.2rem;
    opacity: 1;
  }
  20% {
    top: -0.25rem;
  }
  25% {
    height: 1.40625rem;
    top: -0.625rem;
  }
  45% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 1.40625rem;
    opacity: 1;
    top: -0.5rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
.battery {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -2rem;
  margin-left: -1.12rem;
  width: 2.25rem;
  height: 4.5rem;
  box-shadow: 0 0 0 0.2rem #425c77;
  background: #fff;
  border-radius: 0.09rem;
}
.battery:before {
  content: "";
  position: absolute;
  left: 0.5625rem;
  right: 0.5625rem;
  top: -0.3375rem;
  height: 0.3375rem;
  width: 1.125rem;
  background: #425c77;
  border-radius: 0.18rem;
}
.battery:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-right: 2.25rem solid transparent;
  border-bottom: 4.05rem solid rgba(255, 255, 255, 0.325);
}
.liquid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 2.25rem;
  background: #71fb85;
  animation: load 2.25s infinite;
}
.liquid:after,
.liquid:before {
  content: "";
  position: absolute;
  top: -0.5rem;
  height: 1.125rem;
  width: 1.4625rem;
  background: #71fb85;
  border-radius: 50%;
  opacity: 0;
}
.liquid:after {
  right: 0;
  animation: liquid-1 2.25s infinite;
}
.liquid:before {
  left: 0;
  animation: liquid-2 2.25s infinite;
}

@keyframes power {
  0% {
    color: #bcbcbc;
  }
  75% {
    color: #bcbcbc;
    text-shadow: none;
  }
  90% {
    color: #58fa70;
    text-shadow: 0 0 0.8em #71fb85;
  }
  100% {
    color: #bcbcbc;
    text-shadow: none;
  }
}
.tooltip-inner {
  background-color: white;
  color: black;
  border: 1px solid #dadada;
  text-align: left;
  width: 200px;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .tooltip-inner {
  min-width: 250px;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #dadada;
}
